import React from 'react'
import Flatpickr from "react-flatpickr";
import dayjs from 'dayjs'
import "flatpickr/dist/themes/material_blue.css";

function DatePicker({ value, onSelect, format, className }) {
    return (
        <Flatpickr
            className={className}
            value={value}
            style={{ backgroundColor: '#fff' }}
            onChange={date => {
                onSelect(dayjs(date[0]).format(format))
            }}
        />
    )
}

export default DatePicker