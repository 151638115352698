import * as types from './../actions/actionTypes';
import initialState from './initialState';

export default function deviceReducer(state = initialState.deviceStore, action) {
    switch(action.type) {

        case types.DEVICE_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.FETCH_DEVICES_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                devices: action.payload.devices,
                meta: action.payload.meta,
            }       
        }

        case types.FETCH_DEVICE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                device: action.payload.device
            }       
        }

        case types.STORE_DEVICE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                device: action.payload.device
            }       
        }

        case types.UPDATE_DEVICE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                device: action.payload.device
            }       
        }

        case types.DELETE_DEVICE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                device: {}
            }       
        }

        default: 
            return state;
    }
}
