import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import { useParams, useHistory } from 'react-router'
import { AppOrgResource } from 'api/AppOrgApi'
import Modal from 'components/Modal'
import Loader from 'components/Loader'
import DatePicker from 'components/DatePicker'
import AppOrgApi from 'api/AppOrgApi'
import dayjs from 'dayjs'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

function CreateEditPage() {
    let { id } = useParams()
    let history = useHistory()
    const [state, setState] = useState({
        is_loading: true,
        form: {}
    })

    const [service, setService] = useState({
        form: {},
        loading: false
    })
    const [devicefrm, setDevicefrm] = useState({
        form: {},
        loading: false
    })
    const [modalDevice, setModalDevice] = useState(false)
    const [activityLog, setActivityLog] = useState({ isShow: false })

    useEffect(() => {
        (async() => {
            let res = await AppOrgResource.get(id)
            setState(state => ({...state, is_loading: false, form: res }))
        })()
    }, [id])

    const handleChange = (e) => {
        let {form} = state
        form[e.target.name] = e.target.value;
        setState(state => ({...state, form }))
    }

    const handleChangePermission = (e) => {
        let {form} = state
        form.service_permission[e.target.name] = form.service_permission[e.target.name] === 'active' ? 'inactive' : 'active';
        setState(state => ({...state, form }))
    }

    const handleSave = () => {
        if(id) {
            AppOrgResource.put(state.form, state.form.id)
            .then((res) => {
                if(res.status === 'success') {
                    history.push({pathname: '/organizations', state: {title: 'Org'}})
                }
            })
        } 
    }

    const submitService = async (close) => {
        setService(state => ({ ...state, loading: true }))
        service.form.app_org_id = id
        let res = await AppOrgApi.addService(service.form)
        if(res.status === 'success') {
            close()
            setService(state => ({ ...state, form: {}, loading: false }))
            let resorg = await AppOrgApi.get(id)
            setState(state => ({...state, form: resorg, defaultForm: resorg}))
            resorg.backend_activity_logs && setActivityLog({ isShow: false })
        }
    }

    const submitDevice = async (close) => {
        if(devicefrm.form && devicefrm.form.user_id) {
            setDevicefrm(state => ({ ...state, loading: true }))
            devicefrm.form.app_org_id = id
            let res = await AppOrgApi.addServiceDevice(devicefrm.form)
            if(res.status === 'success') {
                close()
                setDevicefrm(state => ({ ...state, form: {}, loading: false }))
                let resorg = await AppOrgApi.get(id)
                setState(state => ({...state, form: resorg, defaultForm: resorg}))
                resorg.backend_activity_logs && setActivityLog({ isShow: false })
            }
        }
    }

    const deviceChange = (e) => {
        let {form} = devicefrm;
        form[e.target.name] = e.target.value;
        setDevicefrm(state => ({ ...state, form }))
    }

    const serviceChange = (e) => {
        let {form} = service;
        form[e.target.name] = e.target.value;
        setService(state => ({ ...state, form }))
    }

    const deleteService = (serviceId) => {
        confirmAlert({
            title: 'Confirm submit?',
            message: 'confirm delete service',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: async () => {
                        let res = await AppOrgApi.deleteService(serviceId)
                        if(res.status === 'success') {
                            setDevicefrm(state => ({ ...state, form: {}, loading: false }))
                            let resorg = await AppOrgApi.get(id)
                            setState(state => ({...state, form: resorg, defaultForm: resorg}))
                            resorg.backend_activity_logs && setActivityLog({ isShow: false })
                        }
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {}
                }
            ]
        })
    }

    if(state.loading) {
        return (
            <div className="card">
                <div className="card-body">
                    <Loader />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6">
                            <h4>ORG ID: ORG{(1e13 + "" + state.form.id).slice(-6)}</h4>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Link className="btn btn-default" to="/organizations">
                                Cancel
                            </Link>
                            {' '}
                            <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">

                    <div className="card">
                        <div className="card-body">

                            <h5>App Information</h5>

                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <label htmlFor="name">Org Name *</label>
                                    <input type="text" className="form-control" name="org_name" value={state.form.org_name || ''} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <label htmlFor="service_permission">Service permission</label>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="member" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.member === 'active' ? true : false }
                                        /> Office
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="parking" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.parking === 'active' ? true : false }
                                        /> Parking
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="visitor" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.visitor === 'active' ? true : false }
                                        /> Visitor
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="visitor_lite" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.visitor_lite === 'active' ? true : false }
                                        /> Visitor Lite
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="visitor_m" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.visitor_m === 'active' ? true : false }
                                        /> Visitor M
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="visitor_kiosk" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.visitor_kiosk === 'active' ? true : false }
                                        /> Visitor Kiosk
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="check" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.check === 'active' ? true : false }
                                        /> Check
                                    </div>
                                    <div>
                                        <input 
                                            type="checkbox" 
                                            name="note" 
                                            value="active" 
                                            onClick={handleChangePermission}
                                            checked={ state.form.service_permission && state.form.service_permission.note === 'active' ? true : false }
                                        /> Note
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h5>Service</h5>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Modal buttonLabel="Add device" open={modalDevice} hideBtn>
                                        {({ onClose }) => (
                                            <>
                                                <h4>Add Device</h4>
                                                <div className="form-group">
                                                    <label htmlFor="serial_no">Serial No</label>
                                                    <input type="text" className="form-control" name="serial_no" value={devicefrm.form.serial_no} onChange={deviceChange}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="imei">IMEI</label>
                                                    <input type="text" className="form-control" name="imei" value={devicefrm.form.imei} onChange={deviceChange}/>
                                                </div>
                                                {
                                                    state.form.users && state.form.users.length > 0 ?
                                                        <div className="form-group">
                                                            <label htmlFor="user_id">User</label>
                                                            <select name="user_id" className="form-control" value={devicefrm.form.user_id} onChange={(e) => {
                                                                deviceChange(e)
                                                                let ems = state.form.users.find(user => user.id === parseInt(e.target.value)).email.split("@")
                                                                deviceChange({target: {name: 'account_name', value: ems[0]}})
                                                            }}>
                                                                <option value="">-- select user --</option>
                                                                {
                                                                    state.form.users.map(user => {
                                                                        return (
                                                                            <option key={user.id} value={user.id}>{user.name} - {user.email}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    : null
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="account_name">Account Name</label>
                                                    <input type="text" className="form-control" name="account_name" value={devicefrm.form.account_name} onChange={deviceChange}/>
                                                </div>
                                                <hr />
                                                <div className="text-center">
                                                    <button className='btn btn-default' onClick={() => {onClose();setModalDevice(false)}}>Close</button>
                                                    <button className='btn btn-primary' disabled={devicefrm.loading} onClick={() => submitDevice(onClose)}>
                                                        {service.loading ? "Loading..." : "Submit"}
                                                    </button>
                                                </div>
                                            </>
                                         )}
                                    </Modal>
                                    <Modal buttonLabel="Add service">
                                        {({ onClose }) => (
                                            <>
                                                <h4>Add Service</h4>
                                                <div className="form-group">
                                                    <label htmlFor="service">Service</label>
                                                    <select name="service" className="form-control" value={service.form.service} onChange={serviceChange}>
                                                        <option value="">-- --</option>
                                                        <option value="visitor">Visitor</option>
                                                        <option value="check">Check</option>
                                                        <option value="visitor_m">Visitor M</option>
                                                        <option value="visitor_kiosk">Visitor Kiosk</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="service_start_date">Start Date</label>
                                                    <DatePicker className="form-control" value={service.form.service_start_date} format="YYYY-MM-DD" onSelect={(date) => setService(service => ({...service, form: {...service.form, service_start_date: date} })) }/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="age">Age</label>
                                                    <select name="service_age" className="form-control" value={service.form.service_age} onChange={serviceChange}>
                                                        <option value="">-- --</option>
                                                        <option value="1-year">1 Year</option>
                                                        <option value="6-month">6 Month</option>
                                                        <option value="3-month">3 Month</option>
                                                        <option value="1-month">1 Month</option>
                                                    </select>
                                                </div>
                                                <div className="text-center">
                                                    <button className='btn btn-default' onClick={onClose}>Close</button>
                                                    <button className='btn btn-primary' disabled={service.loading} onClick={() => submitService(onClose)}>
                                                        {service.loading ? "Loading..." : "Submit"}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </Modal>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="table-responsive">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Service Name</th>
                                                <th>Start At</th>
                                                <th>Expire At</th>
                                                <th>Device</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                state.form.services && state.form.services.length > 0 ?
                                                    state.form.services.map(service => {
                                                        return (
                                                            <tr key={service.id + service.service}>
                                                                <td>
                                                                    {service.service}
                                                                </td>
                                                                <td>
                                                                    {service.service_start_date ? dayjs(service.service_start_date).format("DD/MM/YYYY") : ""}
                                                                </td>
                                                                <td>
                                                                    {service.service_expire_date ? dayjs(service.service_expire_date).format("DD/MM/YYYY") : ""}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        service.apporg_devices && service.apporg_devices.map((device) => (
                                                                            <div className='d-flex flex-row' key={device.id}>
                                                                                <div>{device.account_name}</div>
                                                                                <div className='pl-10'>{device.user && device.user.email}</div>
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-primary btn-sm' type="button" onClick={() => {setDevicefrm((devicefrm) => ({...devicefrm, form: {apporg_service_id: service.id}}));setModalDevice(true)}}>Add device</button>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-danger btn-sm' type="button" onClick={() => deleteService(service.id)}>
                                                                        <i className='fa fa-trash'></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <h5>Data</h5>
                            <div className="form-group">
                                <label htmlFor="visitor_cccard_limit">Visitor CC-Card Limit *</label>
                                <input type="number" className="form-control" name="visitor_cccard_limit" value={state.form.visitor_cccard_limit || ''} onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="visitor_member_limit">Visitor Member Limit *</label>
                                <input type="number" className="form-control" name="visitor_member_limit" value={state.form.visitor_member_limit || ''} onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="visitor_prepass_limit">Visitor Pre-Pass Limit *</label>
                                <input type="number" className="form-control" name="visitor_prepass_limit" value={state.form.visitor_prepass_limit || ''} onChange={handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateEditPage;
