import cookie from 'react-cookies';
import swal from 'sweetalert';
import * as types from './actionTypes';
import {SERVICE_API} from './../constants';
import { httpClient } from './../utils/HttpClient'

export const FetchDevices = (params) => {
	return async (dispatch) => {
        try {
            dispatch({
                type: types.DEVICE_PENDING,
                payload: null
            })

            let res = await httpClient.get('/backend/devices', {
                params: params,
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
                }
            }).then(res => res.data)

            dispatch({
                type: types.FETCH_DEVICES_SUCCESS,
                payload: {
                    devices: res,
                    meta: {
                        // hasNextPage: res.hasNextPage,
                        // hasPrevPage: res.hasPrevPage,
                        // limit: res.limit,
                        // nextPage: res.nextPage,
                        // page: res.page,
                        // pagingCounter: res.pagingCounter,
                        // prevPage: res.prevPage,
                        // totalDocs: res.totalDocs,
                        // totalPages: res.totalPages
                    }
                }
            })

        } catch(e) {
            // error reading value
        }

	}
}

export const FetchDeviceByID = (id) => {
	return async (dispatch) => {
        try {
            dispatch({
                type: types.DEVICE_PENDING,
                payload: null
            })
            var request = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
                    // 'Authorization': `Bearer f4418386efc9221245203ca511778aca335a4c92`,
                    // 'Content-Type': 'application/json'
                }
            };
            let res = await fetch(SERVICE_API + '/backend/devices/' + id, request).then(res => res.json())

            dispatch({
                type: types.FETCH_DEVICE_SUCCESS,
                payload: {
                    device: res.data
                }
            })

        } catch(e) {
            // error reading value
        }

	}
}

export const StoreDevice = (data) => {
	return async (dispatch) => {
        try {
            dispatch({
                type: types.DEVICE_PENDING,
                payload: null
            })
            var request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`,
                    'Content-Type': 'application/json'
                }
            };
            let res = await fetch(SERVICE_API + '/backend/devices', request).then(res => res.json())

            dispatch({
                type: types.STORE_DEVICE_SUCCESS,
                payload: {
                    device: res.data,
                }
            })

        } catch(e) {
            // error reading value
        }

	}
}

export const UpdateDevice = (data) => {
	return async (dispatch) => {
        try {
            dispatch({
                type: types.DEVICE_PENDING,
                payload: null
            })
            var request = {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`,
                    'Content-Type': 'application/json'
                }
            };
            let res = await fetch(SERVICE_API + '/backend/devices/' + data._id, request).then(res => res.json())

            dispatch({
                type: types.UPDATE_DEVICE_SUCCESS,
                payload: {
                    device: res.data,
                }
            })

        } catch(e) {
            // error reading value
        }

	}
}

export const DeleteDevice = (id) => {
	return async (dispatch) => {
        try {
            swal({
                title: "Are you sure?",
                text: "Once deleted",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (willDelete) => {
                if (willDelete) {

                    dispatch({
                        type: types.DEVICE_PENDING,
                        payload: null
                    })
                    var request = {
                        method: 'DELETE',
                        // body: JSON.stringify(data),
                        headers: {
                            'Authorization': `Bearer ${cookie.load('BNTOKEN')}`,
                            'Content-Type': 'application/json'
                        }
                    };
                    let res = await fetch(SERVICE_API + '/backend/devices/' + id, request).then(res => res.json())
        
                    dispatch({
                        type: types.UPDATE_DEVICE_SUCCESS,
                        payload: {
                            device: res.data,
                        }
                    })
                } 
            });

        } catch(e) {
            // error reading value
        }

	}
}