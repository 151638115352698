import React from 'react';

const FormGroup = ({label, name, value, handleChange, inputType}) => (
    <div className="row form-group">
        <div className="col-sm-12">
            <label htmlFor="name">{label}</label>
            {
                inputType === 'textarea' ?
                    <textarea name={name} onChange={handleChange} className="form-control" value={value}></textarea>
                : ''
            }
            {
                inputType === 'text' || inputType === 'email' || inputType === 'password' ?
                    <input type={inputType} className="form-control" name={name} value={value} onChange={handleChange}/>
                : ''
            }
        </div>
    </div>
)

const FormUser = ({form, handleChange}) => {
    return (
        <div className="row">
            <div className="col-sm-12">
                
                <FormGroup
                    inputType="email"
                    label="Email"
                    name="email"
                    value={form.email || ''}
                    handleChange={handleChange}
                />

                { !form.id && 
                    <FormGroup
                        inputType="password"
                        label="Password"
                        name="password"
                        value={form.password || ''}
                        handleChange={handleChange}
                    />
                }

                <hr/>

                <FormGroup
                    inputType="text"
                    label="Name"
                    name="name"
                    value={form.name || ''}
                    handleChange={handleChange}
                />

                <FormGroup
                    inputType="text"
                    label="Position"
                    name="position"
                    value={form.position || ''}
                    handleChange={handleChange}
                />

                <FormGroup
                    inputType="textarea"
                    label="Address"
                    name="address"
                    value={form.address || ''}
                    handleChange={handleChange}
                />

                <FormGroup
                    inputType="text"
                    label="Tel"
                    name="tel"
                    value={form.tel || ''}
                    handleChange={handleChange}
                />

            </div>
        </div>
    )
}

export default FormUser;