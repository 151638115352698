import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './../actions';
import AuthenticateApi from './../api/AuthenticateApi';
import Header from './common/Header';
import Sidebar from './common/Sidebar';
import Breadcrumb from './common/Breadcrumb';

import Dashboard from './../views/Dashboard';

// Device
import DeviceTrackingOrgPage from './../views/Device/TrackingOrg';

// Management
import BackUserPage from './../views/Management/BackendUser';
import BackUserFormPage from './../views/Management/BackendUser/CreateEditPage';
import UserPage from './../views/Management/User';
import CreateEditUserPage from './../views/Management/User/CreateEditPage';
import AppOrganizationPage from './../views/Management/AppOrganization';
import AppOrganizationSetupPage from './../views/Management/AppOrganization/SetupPage';
import AppOrganizationEditPage from './../views/Management/AppOrganization/EditPage';
import LocationListPage from './../views/Management/Location';
import ResellerPage from './../views/Management/Seller';
import ResellerFormPage from './../views/Management/Seller/CreateEditPage';
import PaymentOverview from './../views/Payment/Overview';
import PayDashboard from './../views/Pay/Dashboard';
import CouponList from './../views/Management/Coupon/CouponList';

const mapStateToProps = (state, ownProps) => {
    return {
        socket: state.socket,
        user: state.user
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

class Full extends React.Component {

    componentWillMount() {
        this.me()
    }

    componentDidMount() {
        document.body.classList.add('sidebar-mini');
        document.body.classList.add('fixed');
    }

    me() {
        AuthenticateApi
            .me()
            .then((res) => {
                this.props.actions.setUser(res.data.user);
            })
            .catch(err => {
                if(err.response && err.response.status === '401') {
                    this.props.history.push('/login');
                }
                if(err.response && err.response.status === '500') {
                    this.props.history.push('/login');
                }
                this.props.history.push('/login');
            })
    }

    Title(pathname) {
        if (pathname === '/dashboard') {
            return 'Dashboard';
        }
    }

    render() {
        let user = this.props.user;
        let title = this.props.location.state ? this.props.location.state.title : this.Title(this.props.location.pathname)
        return (
            <div className="wrapper">
                <Header {...this.props} me={user} />
                <Sidebar {...this.props} user={user}/>
                <div className="content-wrapper">
                    <Breadcrumb title={title} />
                    <Switch>
                        <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
                        <Route path="/devices/trackings/orgs" name="Device Tracking Org" component={DeviceTrackingOrgPage}/>

                        <Route path="/users/create" name="User" component={CreateEditUserPage}/>
                        <Route path="/users/:id" name="User" component={CreateEditUserPage}/>
                        <Route path="/users" name="User" component={UserPage}/>
                        <Route path="/backusers/create" name="User" component={BackUserFormPage}/>
                        <Route path="/backusers/:id" name="User" component={BackUserFormPage}/>
                        <Route path="/backusers" name="User" component={BackUserPage}/>
                        <Route path="/organizations/create" name="Org Create" component={AppOrganizationSetupPage}/>
                        <Route path="/organizations/:id" name="Org Edit" component={AppOrganizationEditPage}/>
                        <Route path="/organizations" name="User" component={AppOrganizationPage}/>
                        <Route path="/locations" name="Location" component={LocationListPage}/>
                        <Route path="/resellers/create" name="Reseller" component={ResellerFormPage}/>
                        <Route path="/resellers/:id" name="Reseller" component={ResellerPage}/>
                        <Route path="/resellers" name="Reseller" component={ResellerPage}/>
                        <Route path="/payments/overview" name="Payment" component={PaymentOverview}/>
                        <Route path="/jp/dashboard" name="Payment" component={PayDashboard}/>
                        <Route path="/coupons" name="Coupon" component={CouponList}/>

                        <Redirect from="/" to="/dashboard"/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Full);
