import React, { useEffect, useState } from "react"
import cookie from 'react-cookies'

function Overview() {
    const [state, setState] = useState({})
    useEffect(() => {
        const getSummary = async () => {
            const jwtToken = cookie.load('BNTOKEN')
            if (jwtToken != null) {
                const res = await fetch("https://api.just-id.com/backend/jp/fee-summary", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${jwtToken}`
                    }
                })
                const { data } = await res.json()
                setState(data)
            }
        }
        getSummary()
    }, [])
    return (
        <div>
            <h1>Just-iD Pay Dashboard</h1>
            <div className="row">
                <div className="col-sm-3 mb-3">
                    <div className="widget-small info"><i className="icon fa fa-cash fa-3x"></i>
                        <div className="info">
                        <h4>Total Fee</h4>
                        <p><b>{state?.totalFee || 0}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview