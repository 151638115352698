import React, { Component } from "react";

class PasswordShowHide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            disabled: true,
            password: ""
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.toggleDisable = this.toggleDisable.bind(this);
    }

    handlePasswordChange(e) {
        if(this.props.onChange) {
            this.props.onChange(e)
        }
        this.setState({ password: e.target.value });
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    toggleDisable() {
        this.setState({ disabled: !this.state.disabled });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.disabled !== nextProps.disabled) {
            this.setState({ disabled: nextProps.disabled });
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ password: this.props.value });
        }
    }

    generate = () => {
        const p = Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8)
        if(this.props.onChange) {
            this.props.onChange({target: {name: 'password', value: p}})
        }
        this.setState({ password: p });
    }

    render() {
        return (
        <div className="input-group">
            <input
                name="password"
                className="form-control"
                type={this.state.hidden ? "password" : "text"}
                value={this.state.password}
                onChange={this.handlePasswordChange}
                disabled={this.state.disabled}
            />
            <span className="input-group-btn">
                <button className="btn btn-default" type="button" onClick={this.toggleShow}><i className="fa fa-eye"></i></button>
                <button className="btn btn-default" type="button" onClick={this.generate}><i className="fa fa-key"></i></button>
                <button className="btn btn-default" type="button" onClick={this.toggleDisable}><i className="fa fa-pencil"></i></button>
            </span>
        </div>
        );
    }
}

export default PasswordShowHide;