import React from 'react';

class Dashboard extends React.Component {

    constructor() {
        super();
        this.state = { someKey: 'someValue' };
    }

    render() {
        return <p>Hello World</p>;
    }

    componentDidMount() {
        this.setState({ someKey: 'otherValue' });
    }

}

export default Dashboard;
