import React, {useEffect, useState} from 'react'
import Pagination from './../../../components/Paginate'
import LocationApi from 'api/LocationApi'

function LocationList() {

    const [state, setState] = useState({
        is_loading: true,
        params: {
            page: 1,
            search: ''
        },
        locations: [],
        metadata: {}
    })

    useEffect(() => {
        (async() => {
            let { docs, ...rest } = await LocationApi.fetch(state.params)
            setState(state => ({
                ...state,
                is_loading: false,
                locations: docs,
                metadata: rest
            }))
        })()
    }, [state.params])

    const pageChange = (page) => {
        setState(state => ({
            ...state,
            is_loading: true,
            params: {
                ...state.params,
                page
            }
        }))
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading" style={{backgroundColor: '#fff', fontWeight: 400}}>
                <h4>Location</h4>
            </div>
            <div className="panel-body">
                {
                    state.is_loading ? <div>Loading...</div> :
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Org ID</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>City</th>
                                <th>Province</th>
                                <th>Postal code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                state.locations && state.locations.length > 0 ?
                                    state.locations.map(location => {
                                        return (
                                            <tr key={location.id}>
                                                <td>{location.app_org_id}</td>
                                                <td>{location.name}</td>
                                                <td>{location.address}</td>
                                                <td>{location.city}</td>
                                                <td>{location.province}</td>
                                                <td>{location.postal_code}</td>
                                            </tr>
                                        )
                                    })
                                : <tr><td colSpan="2"> Data not found</td></tr>
                            }
                        </tbody>
                    </table>
                }
                <Pagination 
                    pageCount={state.metadata.totalPages}
                    itemTotal={state.metadata.totalItems}
                    currentPage={state.metadata.currentPage}
                    rowCount={state.metadata.limit}
                    onPageChange={(number) =>  pageChange(number)}
                />
            </div>
        </div>
    )
}

export default LocationList