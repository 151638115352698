import React, { useEffect } from 'react';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        overflowY: "scroll",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
    },
    content: {
        backgroundColor: "#FFF",
        padding: "15px",
        width: "35%",
        borderRadius: ".5em",
        margin: '0 auto',
        height: 'auto',
        overflow: 'initial'
    },
}

Modal.setAppElement('#modal_root');

function ModalPage({ children, buttonLabel, open, hideBtn }) {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    function openModal() {
        setIsOpen(true);
    }
  
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
  
    function closeModal() {
        setIsOpen(false);
    }
  
    return (
        <div>
            {!hideBtn && <button className='btn btn-primary' onClick={openModal}>{buttonLabel}</button>}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={buttonLabel}
                shouldCloseOnOverlayClick={false}
            >
                {children({ onClose: closeModal})}
            </Modal>
        </div>
    );
}

export default ModalPage