import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from './../../actions';

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={5}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        center={{ lat: props.lat, lng: props.lng }}
    >
        {
            props.isMarkerShown && props.markers && props.markers.length > 0 ?
                props.markers.map(mark => {
                    const onClick = props.onClick.bind(this, mark)
                    return (
                        <Marker onClick={onClick} key={mark._id} position={{ lat: parseFloat(mark.lat), lng: parseFloat(mark.lng) }} icon={{ url: '/images/icon-pin-2.png' }}>
                            {
                                props.selectedMarker === mark &&
                                <InfoWindow onCloseClick={props.onCloseClick}>
                                    <div>
                                        {mark.model_name}, {mark.serial_no}, {mark.tracking_at ? dayjs(mark.tracking_at).format("DD/MM/YYYY HH:mm:ss") :''}
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                })
            : null
        }
    </GoogleMap>
))

function useFetching(someFetchActionCreator, params) {
    useEffect( () => {
      someFetchActionCreator(params);
    }, [])
}

function DeviceTrackingOrg({ devices, actions }) {
    // const [latitude, setLatitude] = useState(null)
    // const [longtitude, setLongtitude] = useState(null)
    const [selectedMarker, setSelectedMarker] = useState(false)

    useFetching(actions.FetchDevices, {})

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <MyMapComponent 
                            lat={13.7245599} lng={100.492683} 
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCRZIajBPcTFJeKNAaNTCB2D8Nqm9hhsrE"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `500px`, width: '100%' }} />}
                            mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                            isMarkerShown
                            markers={devices}
                            onClick={(marker) => setSelectedMarker(marker)}
                            onCloseClick={(marker) => setSelectedMarker(null)}
                            selectedMarker={selectedMarker}
                        />
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <table className="table table-bordered" style={{marginBottom: 0}}>
                            <thead>
                                <tr>
                                    <th>ORG ID</th>
                                    <th>Model</th>
                                    <th>Serial number</th>
                                    <th>IMEI</th>
                                    <th>last tracking</th>
                                    <th>notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    devices && devices.length > 0 ?
                                        devices.map((device) => {
                                            return (
                                                <tr key={device._id} className={`${selectedMarker === device ? 'bg-danger' : ''}`}>
                                                    <td>{device.app_org_id}</td>
                                                    <td>{device.model_name}</td>
                                                    <td>{device.serial_no}</td>
                                                    <td>{device.imei}</td>
                                                    <td>
                                                        {device.tracking_at ? dayjs(device.tracking_at).format("DD/MM/YYYY HH:mm:ss") : ''}
                                                    </td>
                                                    <td>{device.notes}</td>
                                                </tr>
                                            )
                                        })
                                    : null
                                }
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        devices: state.deviceStore.devices,
        devices_meta: state.deviceStore.meta,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTrackingOrg);


