import * as types from './../actions/actionTypes';
import initialState from './initialState';

export default function trackingReducer(state = initialState.trackingStore, action) {
    switch(action.type) {

        case types.TRACKING_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.FETCH_TRACKINGS_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                trackings: action.payload.trackings,
                meta: action.payload.meta,
            }       
        }

        case types.FETCH_TRACKING_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                tracking: action.payload.tracking
            }       
        }

        case types.STORE_TRACKING_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                tracking: {}
            }       
        }

        case types.UPDATE_TRACKING_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                tracking: action.payload.tracking
            }       
        }

        default: 
            return state;
    }
}
