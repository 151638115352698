import React from 'react';
import Autocomplete from 'react-autocomplete';
import {SERVICE_API} from './../../../constants';
import DataApiComponent from '../../../lib/DataApiComponent';

const UserWrapper = DataApiComponent(SERVICE_API + '/backend/users');

class InputComplete extends React.Component {

    handleChange = (e) => {
        this.props.getData({search: e.target.value});
        this.props.onChange(e)
    }

    render() {
        let {value, onSelect, datalists} = this.props;
        return (
            <Autocomplete
                inputProps={{id: 'txtautocompleted', className: 'form-control', name: 'value'}}
                getItemValue={(item) => item.name}
                renderMenu={children => (
                    <div className="menu" style={{top: '34px'}}>
                        {children}
                    </div>
                )}
                items={
                    datalists.map(data => {
                        return {name: data.email, id: data.id, user: data}
                    })
                }
                renderItem={(item, isHighlighted) =>
                    <div 
                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                        key={item.name} 
                    >
                        {item.name}
                    </div>
                }
                wrapperStyle={{ position: 'relative', display: 'block' }}
                sortItems={sortStates}
                shouldItemRender={matchStateToTerm}
                value={value}
                onChange={(e) => this.handleChange(e)}
                onSelect={(val, item) =>  onSelect(val, item)}
            />
        )
    }
}


function matchStateToTerm(state, value) {
    if(value.length === 0)
        return;
    return (
      state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
}

function sortStates(a, b, value) {
    const aLower = a.name.toLowerCase()
    const bLower = b.name.toLowerCase()
    const valueLower = value.toLowerCase()
    const queryPosA = aLower.indexOf(valueLower)
    const queryPosB = bLower.indexOf(valueLower)
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB
    }
    return aLower < bLower ? -1 : 1
}

export default UserWrapper(InputComplete);