import cookie from 'react-cookies';
import BaseApi from './../lib/BaseApi';
import {BASE_API} from './../constants';
import { httpClient } from 'utils/HttpClient'

class AuthenticateApi extends BaseApi
{
    static me() {
        return httpClient.get('/backend/me')
    }

    static login(data) {
        const request = new Request(`${BASE_API}/backend/login`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {'Content-Type': 'application/json'}
        });
        return fetch(request)
            .then(res => res.json())
    }

    static logout() {
        cookie.remove('BNTOKEN');
    }
}

export default AuthenticateApi;