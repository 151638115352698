import React from 'react';
import {Link} from 'react-router-dom';
import AuthenticateApi from './../../api/AuthenticateApi';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdown: false
        }
    }

    open(e) {
        e.preventDefault();
        this.setState({dropdown: !this.state.dropdown});
    }

    logout(e) {
        e.preventDefault();
        AuthenticateApi.logout();
        this.props.history.push('/login');
    }

    render() {
        return (
            <header className="main-header hidden-print">
                <a className="logo" href="/" style={{backgroundColor: '#222d32'}}>
                    <img src={'https://app.just-id.com/images/Logo.png'} alt="" style={{height: '100%'}}/>
                </a>
                <nav className="navbar navbar-static-top">
                    <a className="sidebar-toggle" href="" data-toggle="offcanvas"> </a>
                    <div className="navbar-custom-menu">
                        <ul className="top-nav">
                            <li className="dropdown notification-menu"><a className="dropdown-toggle" href="" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-bell-o fa-lg"></i></a>
                            <ul className="dropdown-menu">
                                <li className="not-head">You have 4 new notifications.</li>
                                <li>
                                    <a className="media" href="">
                                        <span className="media-left media-icon">
                                            <span className="fa-stack fa-lg">
                                                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                                                <i className="fa fa-envelope fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </span>
                                        <div className="media-body">
                                            <span className="block">Lisa sent you a mail</span>
                                            <span className="text-muted block">2min ago</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="media" href="">
                                        <span className="media-left media-icon">
                                            <span className="fa-stack fa-lg">
                                                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                                <i className="fa fa-hdd-o fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </span>
                                        <div className="media-body">
                                            <span className="block">Server Not Working</span>
                                            <span className="text-muted block">2min ago</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="media" href="">
                                        <span className="media-left media-icon">
                                            <span className="fa-stack fa-lg">
                                                <i className="fa fa-circle fa-stack-2x text-success"></i>
                                                <i className="fa fa-money fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </span>
                                        <div className="media-body">
                                            <span className="block">Transaction xyz complete</span>
                                            <span className="text-muted block">2min ago</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="not-footer"><a href="">See all notifications.</a></li>
                            </ul>
                            </li>
                            <li className={`dropdown ${this.state.dropdown ? 'open' : ''}`}>
                                <a className="dropdown-toggle" onClick={(e) => this.open(e)} href="" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user fa-lg"></i></a>
                                <ul className="dropdown-menu settings-menu">
                                    {/*<li><a href="page-user.html"><i className="fa fa-cog fa-lg"></i> Settings</a></li>*/}
                                    <li><Link to={`/profile`}><i className="fa fa-user fa-lg"></i> Profile</Link></li>
                                    <li><a href="" onClick={(e) => this.logout(e)}><i className="fa fa-sign-out fa-lg"></i> Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
                <form id="logout-form" action="/logout" method="POST" style={{display: 'none'}}></form>
            </header>
        )
    }
}

export default Header;