export const SET_SOCKET_FUNCTION = 'SET_SOCKET_FUNCTION';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

// Location
export const LOCATION_PENDING = 'LOCATION_PENDING'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS'
export const STORE_LOCATION_SUCCESS = 'STORE_LOCATION_SUCCESS'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'

export const DEVICE_PENDING = 'DEVICE_PENDING'
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS'
export const FETCH_DEVICE_SUCCESS = 'FETCH_DEVICE_SUCCESS'
export const STORE_DEVICE_SUCCESS = 'STORE_DEVICE_SUCCESS'
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS'
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS'

export const TRACKING_PENDING = 'TRACKING_PENDING'
export const FETCH_TRACKINGS_SUCCESS = 'FETCH_TRACKINGS_SUCCESS'
export const FETCH_TRACKING_SUCCESS = 'FETCH_TRACKING_SUCCESS'
export const STORE_TRACKING_SUCCESS = 'STORE_TRACKING_SUCCESS'
export const UPDATE_TRACKING_SUCCESS = 'UPDATE_TRACKING_SUCCESS'