import * as types from './actionTypes';

export function setSocketFunctionSuccess(socket) {
    return {type: types.SET_SOCKET_FUNCTION, socket};
}

export function setSocketFunction(func) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function(dispatch) {
        return dispatch(setSocketFunctionSuccess(func));
    };
}