import React from 'react';
import cookie from 'react-cookies';

const DataApi = (url) => {
    let ApiUrl = url
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
    }
    return (Wrapper) => {
        return class extends React.Component {
    
            constructor() {
                super();
                this.state = {
                    lists: [],
                    meta: {},
                    search: '',
                    item: {}
                }
            }
    
            serialize = (obj) => {
                let str = [];
                for(let p in obj)
                    if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
                return str.join("&");
            }
            
            getApi = (url) => {
                const request = new Request(url ? url : ApiUrl, {
                    method: 'GET',
                    headers: headers
                });
                return fetch(request)
                    .then(res => res.json())
            }

            api = () => {
                return {
                    get: this.getData,
                    getById: this.getDataById,
                    post: this.postData,
                    put: this.putData,
                    delete: this.deleteData,
                }
            }

            /**
             * @name Get data from api method
             * @param payload Object
             *  payload {
             *      page
             *      search
             *  }
             * 
             * @return null
             */
            getData = (payload = {}) => {
                let data = { page: payload.page ? payload.page : 1 };
                if(payload && payload.search && payload.search.length > 0) {
                    data.search = payload.search;
                }
                let queryParam = data !== undefined ? '?' + this.serialize(data) : ''
                const request = new Request(ApiUrl + queryParam, {
                    method: 'GET',
                    headers: headers
                });
                return fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({lists: res.data, meta: res.meta});
                        return res;
                    });
            }
    
            /**
             * @name Get data by id from api method 
             * @param id integer
             * @return null
             */
            getDataById = (id) => {
                const request = new Request(ApiUrl + id, {
                    method: 'GET',
                    headers: headers
                });
                return fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({item: res});
                        return res;
                    });
            }

            /**
             * @name Post data to api server 
             * @param data Object
             * @return response Object
             */
            postData = (data, callback) => {
                const request = new Request(ApiUrl, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: headers
                });
                return fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({item: res});
                        if(callback)
                            callback(res)
                        return res;
                    });
            }

            /**
             * @name put data to api server 
             * @param data Object
             * @return response Object
             */
            putData = (data, callback) => {
                const request = new Request(ApiUrl + '/' + data.id, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: headers
                });
                return fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({item: res});
                        if(callback)
                            callback(res)
                        return res;
                    });
            }

            /**
             * @name delete data to api server 
             * @param data Object
             * @return response Object
             */
            deleteData = (data, callback) => {
                const request = new Request(ApiUrl + '/' + data.id, {
                    method: 'DELETE',
                    headers: headers
                });
                return fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({item: res});
                        if(callback)
                            callback(res)
                        return res;
                    });
            }
    
            /**
             * @name render from Wrapper param
             */
            render() {
                let {lists, meta, data} = this.state;
                return (
                    <Wrapper 
                        {...this.props} 
                        datalists={lists} 
                        data={data} 
                        meta={meta} 
                        api={this.api} 
                        getApi={this.getApi} 
                        getData={this.getData} 
                        getDataById={this.getDataById}
                        postData={this.postData}
                        RenderPagination={this.RenderPagination}
                        RenderFormSearch={this.RenderFormSearch}
                    />
                )
            }

            /**
             * @name call search data from api
             */
            search = (e) => {
                e.preventDefault();
                this.getData({search: this.state.search});
            }

            /**
             * @name render search box component
             */
            RenderFormSearch = () => {
                return (
                    <form onSubmit={this.search}>
                        <input type="text" className="form-control" value={this.state.search} placeholder="Search..." onChange={(e) => this.setState({search: e.target.value})}/>
                    </form>
                )
            }

            /**
             * @name render pagination component
             */
            RenderPagination = () => {
                let {lists, meta} = this.state;
                if(!meta || !lists)
                    return null 
                return (
                    <div className="row">
                        <div className="col-sm-6">
                            Showing {meta ? (meta.page * meta.pageSize - meta.pageSize) + 1 : 0} to {meta ? (meta.page * meta.pageSize - meta.pageSize) + lists.length : 0} of {meta ? meta.rowCount : 0} entries
                        </div>
                        <div className="col-sm-6 text-right">
                            
                        </div>
                    </div>
                )
            }
        }
    
    }
}

export default DataApi;