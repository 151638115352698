import * as types from './actionTypes';
import axios from 'axios'
import cookie from 'react-cookies';
import {SERVICE_API} from './../constants';

export const FetchOrgTrackings = (params) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.TRACKING_PENDING,
                payload: null
            })
            let res = await axios({
                method: 'get',
                url: SERVICE_API + '/backend/trackings/orgs',
                params: params,
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
                }
            })
            .then(res => res.data);
            dispatch({
                type: types.FETCH_TRACKINGS_SUCCESS,
                payload: {
                    trackings: res.docs,
                    meta: {
                        hasNextPage: res.hasNextPage,
                        hasPrevPage: res.hasPrevPage,
                        limit: res.limit,
                        nextPage: res.nextPage,
                        page: res.page,
                        pagingCounter: res.pagingCounter,
                        prevPage: res.prevPage,
                        totalDocs: res.totalDocs,
                        totalPages: res.totalPages
                    }
                }
            })
        } catch(err) {

        }
    }
}

export const FetchTrackings = (params) => {
	return async (dispatch) => {
        try {
            dispatch({
                type: types.TRACKING_PENDING,
                payload: null
            })
            
            // let res = await fetch(SERVICE_API + '/backend/trackings', request).then(res => res.json())
            let res = await axios({
                method: 'get',
                url: SERVICE_API + '/backend/trackings',
                params: params,
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
                }
            }).then(res => res.data);
            dispatch({
                type: types.FETCH_TRACKINGS_SUCCESS,
                payload: {
                    trackings: res.docs,
                    meta: {
                        hasNextPage: res.hasNextPage,
                        hasPrevPage: res.hasPrevPage,
                        limit: res.limit,
                        nextPage: res.nextPage,
                        page: res.page,
                        pagingCounter: res.pagingCounter,
                        prevPage: res.prevPage,
                        totalDocs: res.totalDocs,
                        totalPages: res.totalPages
                    }
                }
            })

        } catch(e) {
            // error reading value
        }

	}
}