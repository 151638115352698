import React from 'react';

class Breadcrumb extends React.Component {

    render() {
        let {title} = this.props
        return (
            <div className="page-title">
                <div>
                    <ul className="breadcrumb" style={{padding: '8px 0px'}}>
                        <li><i className="fa fa-home fa-lg"></i> Backend</li>
                        <li>{title}</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Breadcrumb;