import * as types from './actionTypes';
import axios from 'axios'
import cookie from 'react-cookies';
import {SERVICE_API} from './../constants';

export const FetchLocations = (params) => {
	return async (dispatch) => {
        try {
            dispatch({
                type: types.LOCATION_PENDING,
                payload: null
            })
            
            // let res = await fetch(SERVICE_API + '/backend/trackings', request).then(res => res.json())
            let res = await axios({
                method: 'get',
                url: SERVICE_API + '/backend/locations',
                params: params,
                headers: {
                    'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
                }
            }).then(res => res.data);
            dispatch({
                type: types.FETCH_LOCATIONS_SUCCESS,
                payload: {
                    locations: res.data,
                    meta: res.meta
                }
            })

        } catch(e) {
            // error reading value
        }

	}
}