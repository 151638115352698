import React, { useEffect, useState } from 'react'
import dayjs from "dayjs"
import CouponApi from './../../../api/CouponApi'
import Pagination from './../../../components/Paginate'

function CouponList() {
    const [search, setSearch] = useState('')
    const [state, setState] = useState({
        params: {
            search: '',
            page: localStorage.getItem('page') ? localStorage.getItem('page') : 1,
        },
        items: [],
        metadata: localStorage.getItem('metadata') ? JSON.parse(localStorage.getItem('metadata')) : {}
    })

    useEffect(() => {
        (async() => {
            let { docs, ...rest } = await CouponApi.query(state.params)
            localStorage.setItem('metadata', JSON.stringify(rest))
            setState(state => ({
                ...state,
                items: docs,
                metadata: rest
            }))
        })()
    }, [state.params])

    const onSearch = (e) => {
        e.preventDefault()
        setState(state => ({
            ...state,
            params: {
                ...state.params,
                search,
            }
        }))
    }

    const pageChange = (p) => {
        localStorage.setItem('page', p)
        setState(state => ({
            ...state,
            params: {
                ...state.params,
                page: p,
            }
        }))
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row form-group">
                    <div className="col-sm-4">
                        <form onSubmit={onSearch}>
                            <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </form>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Code</th>
                            <th>Value</th>
                            <th>Coupon Expired</th>
                            <th>ORG</th>
                            <th>Redemption At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.items && state.items.length > 0 && state.items.map(coupon => {
                                return (
                                    <tr key={coupon.id}>
                                        <td>{coupon.id}</td>
                                        <td>{coupon.coupon_type}</td>
                                        <td>{coupon.coupon_code}</td>
                                        <td>{coupon.expiry_date ? dayjs(coupon.expiry_date).format("DD/MM/YYYY") : ''}</td>
                                        <td>{coupon.discount_value}{coupon.discount_type === 'percent' ? '%' : ''}</td>
                                        <td>
                                            {coupon.apporg && coupon.apporg.id ? `ORG${(1e13 + "" + coupon.apporg.id).slice(-6)} ` : ''}
                                            {coupon.apporg && coupon.apporg.id ? ' ' + coupon.apporg.org_name : ''}
                                        </td>
                                        <td>{coupon.redemption_at ? dayjs(coupon.redemption_at).format("DD/MM/YYYY") : ''}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination 
                    currentPage={state.metadata.currentPage}
                    pageCount={state.metadata.totalPages}
                    rowCount={10}
                    itemTotal={state.metadata.totalItems}
                    onPageChange={(n) => pageChange(n)}
                />
            </div>
        </div>
    )
}


export default CouponList