import * as types from './../actions/actionTypes';
import initialState from './initialState';

export default function socketReducer(state = initialState.socket, action) {
    switch(action.type) {
        case types.SET_SOCKET_FUNCTION:
            return action.socket;
        default: 
            return state;
    }
}