import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { useParams, useHistory } from 'react-router'
import FormUser from './common/FormUser'
import UserApi from './../../../api/BackendUserApi'

function CreateEditPage() {

    const { id } = useParams()
    const history = useHistory()

    const [state, setState] = useState({
        form: {}
    })

    useEffect(() => {
        (async() => {
            if(id) {
                let res = await UserApi.get(id)
                setState(state => ({...state, form: res }))
            }
        })()
    }, [id])

    const handleChange = (e) => {
        let {form} = state;
        form[e.target.name] = e.target.value;
        setState(state => ({...state, form }))
    }

    const handleSave = () => {
        if(id) {
            UserApi
                .update(state.form, id)
                .then((res) => {
                    if(res.status === 'success') {
                        history.push({pathname: '/backusers', state: {title: 'User'}})
                    }
                })
        } else {
            UserApi
                .create(state.form)
                .then((res) => {
                    if(res.status === 'success') {
                        history.push({pathname: '/backusers', state: {title: 'User'}})
                    }
                })
        }
    }

    const resetPassword = () => {
        if(id) {
            UserApi
                .setpassword(state.form, id)
                .then((res) => {
                    if(res.status === 'success') {
                        history.push({pathname: '/backusers', state: {title: 'User'}})
                    }
                })
        }
    }

    return (
        <div className="row">
            <div className="col-sm-12">

                <div className="row">
                    <div className="col-sm-6">
                    
                        <div className="card">
                            <div className="card-body">
        
                                <FormUser
                                    form={state.form}
                                    handleChange={handleChange}
                                />

                                <div className="form-group text-center">
                                    <Link className="btn btn-default" to={{pathname:`/backusers`, state: {title: 'User'}}}>
                                        Cancel
                                    </Link>
                                    {' '}
                                    <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                    
                        { state.form && state.form.id && 
                            <div className="card">
                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-12">
                                            <label htmlFor="name">Change password</label>
                                            <input type="password" className="form-control" name="password" value={state.form.password} onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary" type="button" onClick={resetPassword}>Set password</button>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateEditPage;
