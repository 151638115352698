import cookie from 'react-cookies'
import BaseApi from 'lib/BaseApi'
import ResourceApi from 'utils/ResourceApi'
import {BASE_API} from './../constants';

// class AppOrgResource extends ResourceApi {}

export const AppOrgResource = new ResourceApi('/backend/apporganizations')

class AppOrgApi extends BaseApi
{
    static url() {
        return '/backend/apporganizations';
    }

    static type() {
        return 'apporganizations';
    }

    static store(data) {
        const request = new Request(this.url(), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
            }
        });
        return fetch(request)
            .then(res => res.json())
    }

    static update(data, id) {
        const request = new Request(this.url() + '/' + id, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
            }
        });
        return fetch(request)
            .then(res => res.json())
    }

    static uploadDocument(file, id) {
        const formData = new FormData()
        formData.append('file', file)
        const request = new Request(BASE_API + this.url() + '/' + id + '/document', {
            method: 'POST',
            body: formData,
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
            }
        });
        return fetch(request)
            .then(res => res.json())
    }

    static addService(data) {
        const request = new Request(BASE_API + "/backend/apporg-services", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
            }
        });
        return fetch(request)
            .then(res => res.json())
    }

    static addServiceDevice(data) {
        const request = new Request(BASE_API + "/backend/apporg-service-devices", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
            }
        });
        return fetch(request)
            .then(res => res.json())
    }

    static deleteService(id) {
        const request = new Request(BASE_API + "/backend/apporg-services/"+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookie.load('BNTOKEN')}`
            }
        });
        return fetch(request)
            .then(res => res.json())
    }
}

export default AppOrgApi;