// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import registerServiceWorker from './registerServiceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';

import Full from './containers/Full';
import Login from './containers/Login';

import './style.css';

const store = configureStore();

ReactDOM.render((
    <Provider store={store}>
        <Router>
            <Switch>
                <Route path="/login" name="Login" component={Login}/>
                <Route path="/" name="Home" component={Full}/>
            </Switch>
        </Router>
    </Provider>
), document.getElementById('root'));