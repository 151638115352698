import React from 'react';
import {Link} from 'react-router-dom';

class Sidebar extends React.Component {
    // static contextTypes = {
    //     router: PropTypes.object
    // }
    // constructor(props, context) {
    //     super(props);
    // }

    // goto(e, pathname, stateval) {
    //     e.preventDefault();
    //     this.context.router.history.push(pathname, stateval);
    // }
    render() {
        let {user} = this.props
        return (
            <aside className="main-sidebar hidden-print">
                <section className="sidebar">
                    <div className="user-panel">
                        <div className="pull-left image">
                            <img className="img-circle" src="https://via.placeholder.com/150?Text=just-id.com" alt="img-profile"/>
                        </div>
                        <div className="pull-left info">
                            <p>{user ? user.name : ''}</p>
                            <p className="designation">{user ? user.email:''}</p>
                        </div>
                    </div>
                    <ul className="sidebar-menu">
                        <li>
                            <Link to={{pathname:`/dashboard`, state: {title: 'Dashboard'}}}>
                                <i className="fa fa-dashboard"></i> Dashboard
                            </Link>
                        </li>
                        <li className="treeview"><a href=""><i className="fa fa-mobile"></i><span>Device</span><i className="fa fa-angle-right"></i></a>
                            <ul className="treeview-menu">
                                
                                <li>
                                    <Link to={{pathname:`/devices/trackings/orgs`, state: {title: 'Tracking'}}}>
                                        <i className="fa fa-map-marker"></i> Tracking Org
                                    </Link>
                                </li>
                                
                            </ul>
                        </li>
                        <li className="treeview"><a href=""><i className="fa fa-info-circle"></i><span>Management</span><i className="fa fa-angle-right"></i></a>
                            <ul className="treeview-menu">
                                <li>
                                    <Link to={{pathname:`/organizations`, state: {title: 'Organization'}}}>
                                        <i className="fa fa-building-o"></i> Organization
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname:`/locations`, state: {title: 'Location'}}}>
                                        <i className="fa fa-building-o"></i> Location
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname:`/users`, state: {title: 'User'}}}>
                                        <i className="fa fa-users"></i> User
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname:`/backusers`, state: {title: 'User'}}}>
                                        <i className="fa fa-users"></i> Backend User
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "/coupons", state: { title: "Coupon" } }}>
                                        <i className='fa fa-users'></i> Coupon
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="treeview">
                            <a href=""><i className="fa fa-info-circle"></i><span>Payment</span><i className="fa fa-angle-right"></i></a>
                            <ul className="treeview-menu">
                                <li>
                                    <Link to={{pathname:`/payments/overview`, state: {title: 'Overview'}}}>Overview</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="treeview">
                            <a href=""><i className="fa fa-info-circle"></i><span>JustiD Pay</span><i className="fa fa-angle-right"></i></a>
                            <ul className="treeview-menu">
                                <li>
                                    <Link to={{pathname:`/jp/dashboard`, state: {title: 'Dashboard'}}}>Dashboard</Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li className="treeview">
                            <a href=""><i className="fa fa-info-circle"></i><span>System</span><i className="fa fa-angle-right"></i></a>
                            <ul className="treeview-menu">
                                <li>
                                    <Link to={{pathname:`/schedules`, state: {title: 'Schedules'}}}>Schedule</Link>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </section>
            </aside>
        )
    }
}

export default Sidebar;