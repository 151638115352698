import * as types from './../actions/actionTypes';
import initialState from './initialState';

export default function locationReducer(state = initialState.locationStore, action) {
    switch(action.type) {

        case types.LOCATION_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.FETCH_LOCATIONS_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                locations: action.payload.locations,
                meta: action.payload.meta,
            }       
        }

        case types.FETCH_LOCATION_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                location: action.payload.location
            }       
        }

        case types.STORE_LOCATION_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                location: {}
            }       
        }

        case types.UPDATE_LOCATION_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                location: action.payload.location
            }       
        }

        default: 
            return state;
    }
}
