import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import {AppOrgResource} from 'api/AppOrgApi'
import InputPassword from 'components/common/InputPasswordShowHide'
import { Formik, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from "yup"

const InputGroup = ({ name, type, label, error, as}) => {
    return (
        <div className={`form-group ${error ? 'has-error' : ''}`}>
            <span className="has-float-label">
                <label htmlFor={name}>{label}</label>
                <Field type={type} as={as} id={name} name={name} placeholder={label} className="form-control"/>
            </span>
            <ErrorMessage name={name}>{msg => <div className="help-block">{msg}</div>}</ErrorMessage>
        </div>
    )
}

function SetupPage() {

    const history = useHistory()

    const handleSave = (values) => {
        AppOrgResource.post(values)
        .then((res) => {
            if(res.status === 'success') {
                history.push({pathname: '/organizations', state: {title: 'Org'}})
            }
        })
    }

    return (
        <Formik 
            initialValues={{}}
            enableReinitialize
            onSubmit={(values) => handleSave(values)}
            validationSchema={
                Yup.object().shape({
                    org_name: Yup.string().required().label('Org name')
                })
            }
        >
            {
                ({ values, handleSubmit, errors, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="line-head">Setup new org</h4>
                                    <div className="row">
                                        <div className="col-sm-4">

                                            <InputGroup label="Org name *" name="org_name" type="text" as="input" error={errors.org_name} />

                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    <label htmlFor="service_permission">Service permission</label>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="member" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.member', values.service_permission && values.service_permission.member === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.member === 'active' ? true : false }
                                                        /> Office
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="parking" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.parking', values.service_permission && values.service_permission.parking === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.parking === 'active' ? true : false }
                                                        /> Parking
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="visitor" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.visitor', values.service_permission && values.service_permission.visitor === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.visitor === 'active' ? true : false }
                                                        /> Visitor
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="visitor_lite" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.visitor_lite', values.service_permission && values.service_permission.visitor_lite === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.visitor_lite === 'active' ? true : false }
                                                        /> Visitor Lite
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="visitor_m" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.visitor_m', values.service_permission && values.service_permission.visitor_m === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.visitor_m === 'active' ? true : false }
                                                        /> Visitor M
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="visitor_kiosk" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.visitor_kiosk', values.service_permission && values.service_permission.visitor_kiosk === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.visitor_kiosk === 'active' ? true : false }
                                                        /> Visitor Kiosk
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="check" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.check', values.service_permission && values.service_permission.check === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.check === 'active' ? true : false }
                                                        /> Check
                                                    </div>
                                                    <div>
                                                        <input 
                                                            type="checkbox" 
                                                            name="note" 
                                                            value="active" 
                                                            onClick={() => setFieldValue('service_permission.note', values.service_permission && values.service_permission.note === 'active' ? 'inactive' : 'active' )}
                                                            checked={ values.service_permission && values.service_permission.note === 'active' ? true : false }
                                                        /> Note
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <FieldArray
                                                name="users"
                                                render={arrayHelpers => (
                                                    <div>
                                                        <p>User <button className="btn btn-primary btn-xs" type="button" onClick={() => arrayHelpers.push({})}><i className="fa fa-plus"></i></button></p>
                                                        {
                                                            values.users && values.users.map((user, index) => {
                                                                let userErr = errors.users && errors.users[index] ? errors.users[index] : {}
                                                                return (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-sm-12">
                                                                            <InputGroup label="Email *" name={`users[${index}].email`} type="email" as="input" error={userErr.email} />
                                                                            <InputPassword value={user.password || ''} onChange={(e) => setFieldValue(`users[${index}].password`,e.target.value)}/>
                                                                            <hr/>
                                                                            <InputGroup label="Name *" name={`users[${index}].name`} type="text" as="input" error={userErr.name} />
                                                                            <InputGroup label="Position" name={`users[${index}].position`} type="text" as="input" error={userErr.position} />
                                                                            <InputGroup label="Address" name={`users[${index}].address`} type="text" as="textarea" error={userErr.address} />
                                                                            <InputGroup label="Tel *" name={`users[${index}].tel`} type="text" as="input" error={userErr.tel} />
                                                                            <button className="btn btn-xs btn-danger" type="button" onClick={() => arrayHelpers.remove(index)}>Del</button>
                                                                            <hr/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <Link className="btn btn-default" to={{pathname:`/organizations`, state: {title: 'Org'}}}>
                                                Cancel
                                            </Link>
                                            {' '}
                                            <button className="btn btn-primary" type="submit">Save</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    )
                }
            }
        </Formik>
    )
}

export default SetupPage