import axios from 'axios';
import cookie from 'react-cookies';
import { httpClient } from 'utils/HttpClient'

class BaseApi {

    static serialize(obj) {
        let str = [];
        for(let p in obj)
            if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }

    static handleError(error) {
        // switch (error.response.status) {
        // case 401:
        //     // this.redirectTo(document, '/')
        //     // browserHistory.push('/login')
        //     break;
        // case 404:
        //     // this.redirectTo(document, '/404')
        //     break;
        // default:
        //     // this.redirectTo(document, '/500')
        //     // browserHistory.push('/login')
            
        //     break;
        // }
        return Promise.reject(error)
    }

    static fetchData(payload) {
        return axios({
                ...payload,
                transformRequest: [function (data, headers) {
                    // Do whatever you want to transform the data
                    // headers.common['Content-Type'] = `application/json`
                    headers.common['Authorization'] = `Bearer ${cookie.load('BNTOKEN')}`
                    return data;
                }]
            })
            .then((res) => res.data)
            .catch(this.handleError)
    }

    static get(id) {
        return httpClient.get(this.url() + '/' + id).then(res => res.data)
    }

    static query(params) {
        return httpClient.get(this.url(), { params }).then(res => res.data)
    }

    static post(data) {
        return httpClient.post(this.url(), data).then(res => res.data)
    }

    static put(data, id) {
        return httpClient.put(this.url() + '/' + id, data).then(res => res.data)
    }

    static delete(id) {
        return httpClient.delete(this.url() + '/' + id).then(res => res.data)
    }

    static save(data) {
        let id = data.id || null
        // let url =  data.id ? `${this.url()}/${id}` : this.url()
        let formData = {
            data: {
                type: this.type(),
                attributes: data
            }
        }
        if(id) {
            return this.put(formData, id)
        } else {
            return this.post(formData)
        }
    }

}

export default BaseApi;