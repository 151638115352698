import React from "react"
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import './index.css'

const Paginate = ({ currentPage, pageCount, rowCount, itemTotal, onPageChange }) => {
    return (
        <div className="row">
            <div className="col-sm-5" style={{paddingTop: 5}}>
                Showing {(currentPage * rowCount - rowCount) + 1 } to {(currentPage * rowCount - rowCount) + rowCount} of {itemTotal} entries
            </div>
            <div className="col-sm-7 text-right">
                <ReactPaginate
                    pageCount={pageCount}
                    forcePage={currentPage ? currentPage - 1 : 1 }
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    onPageChange={(obj) => onPageChange(obj.selected + 1)}
                    containerClassName={'pagination pagination-sm'}
                    subContainerClassName={'page-item'}
                    activeClassName={'active'}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                />
            </div>
        </div>
    )
}

Paginate.propTypes = {
    itemTotal: PropTypes.number,
    pageCount: PropTypes.number,
    currentPage: PropTypes.number,
    rowCount: PropTypes.number,
}

Paginate.defaultProps = {
    itemTotal: 10,
    pageCount: 1,
    currentPage: 1,
    rowCount: 10
}

export default Paginate