import React from 'react';
import {Link} from 'react-router-dom';
import {SERVICE_API} from './../../../constants';
import DataApiComponent from '../../../lib/DataApiComponent';
import UserAutoComplete from './_UserAutoComplete';

const SellerWrapper = DataApiComponent(SERVICE_API + '/backend/resellers');
class FormSeller extends React.Component {

    state = {
        form: {},
        select: ''
    }

    componentDidMount = () => {
        if(this.props.match.params.id) {
            this.props.getDatabyId(this.props.match.params.id);
        }
    }

    addReseller = () => {
        let {form} = this.state;
        this.props.postData({user_id: form.id}, (res) => {
            this.props.history.push('/resellers');
        });
    }

    render() {
        let {select, form} = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="line-head" style={{marginTop: 0}}>Create Reseller</h5>
                    <div className="row form-group">
                        <div className="col-sm-3 text-right" style={{padding: '8px'}}>Email</div>
                        <div className="col-sm-6">
                            <UserAutoComplete
                                value={select}
                                onChange={(e) => this.setState({select: e.target.value})}
                                onSelect={(val, item) =>  this.setState({select: val, form: item.user})}
                            />
                        </div>
                    </div>
                    {
                        form.name ?
                            <div className="row form-group">
                                <div className="col-sm-10 col-sm-offset-1">
                                    <div className="alert alert-info">
                                        <p>Name: {form.name}</p>
                                        <p>Email: {form.email}</p>
                                        <p>Tel: {form.tel}</p>
                                        <div className="row">
                                            <div className="col-sm-12 text-right">
                                                <button className="btn btn-link" type="button" onClick={() => this.addReseller()}>Add Reseller</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null
                    }
                        
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <Link to="resellers" className="btn btn-default">Cancel</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SellerWrapper(FormSeller);