import React from 'react';
import {Link} from 'react-router-dom';
import {SERVICE_API} from './../../../constants';
import DataApiComponent from '../../../lib/DataApiComponent';

const SellerWrapper = DataApiComponent(SERVICE_API + '/backend/resellers');
class Seller extends React.Component {

    state = {
        search: ''
    }

    componentDidMount = () => {
        this.props.getData();
    }

    submit = (e) => {
        e.preventDefault();
        this.props.api().get({search: this.state.search});
    }

    del = (id) => {
        this.props.delData(id);
    }

    sendInvite = (id) => {
        this.props.getApi(SERVICE_API + '/backend/resellers/sendinvite/' + id)
        .then(res => {
            alert(res.message);
        })
    }

    render() {
        let {datalists} = this.props;
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row form-group">
                        <div className="col-sm-8">
                            <Link to="/resellers/create" className="btn btn-primary">Create Reseller</Link>
                        </div>
                        <div className="col-sm-4">
                            <form onSubmit={this.submit}>
                                <input type="text" className="form-control" placeholder="Search..." value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
                            </form>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Seller ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Namecard</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                datalists && datalists.length > 0 ?
                                    datalists.map((sell, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{sell.id}</td>
                                                <td>{sell.user ? sell.user.name : ''}</td>
                                                <td>{sell.user ? sell.user.email : ''}</td>
                                                <td>{sell.status === 'active' ? 'Active' : 'Inactive'}</td>
                                                <td className="text-center">{sell.namecard_count}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Action <span className="caret"></span>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a href="" onClick={(e) => {this.sendInvite(sell.user_id);e.preventDefault()}}>Send User login</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                : 
                                    <tr><td colSpan="6">Data Not Found</td></tr>
                            }
                        </tbody>
                    </table>
                    {
                        this.props.RenderPagination()
                    }
                </div>
            </div>
        )
    }
}

export default SellerWrapper(Seller);