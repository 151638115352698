import { httpClient } from './HttpClient'

class ResourceApi {

    constructor(path) {
        this.path = path;
    }

    static handleError(error) {
        // switch (error.response.status) {
        // case 401:
        //     // this.redirectTo(document, '/')
        //     // browserHistory.push('/login')
        //     break;
        // case 404:
        //     // this.redirectTo(document, '/404')
        //     break;
        // default:
        //     // this.redirectTo(document, '/500')
        //     // browserHistory.push('/login')
        //     break;
        // }
        return Promise.reject(error)
    }

    fetch = async (params) => {
        const response = await httpClient.get(this.path, { params })
        return response.data
    }

    get = async (id) => {
        const response = await httpClient.get(this.path + '/' + id)
        return response.data
    }

    post = async (data) => {
        const response = await httpClient.post(this.path, data)
        return response.data
    }

    put = async (data, id) => {
        const response = await httpClient.put(this.path + '/' + id, data)
        return response.data
    }

    delete = async (id) => {
        const response = await httpClient.delete(this.path + '/' + id)
        return response.data
    }
}

export default ResourceApi