export default {
    socket: null,
    user: null,
    deviceStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        devices: [],
        device: {},
        meta: {}
    },
    locationStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        locations: [],
        location: {},
        meta: {}
    },
    trackingStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        trackings: [],
        tracking: {},
        meta: {}
    }
}