import React, { useState, useEffect } from 'react'
import './index.css'

function Switch({isChecked, onChange, disabled = false}) {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])

    return (
        <div className="switch-container">
            <label>
                <input checked={ checked } disabled={disabled} onChange={() => {onChange(!checked);setChecked(!checked)} } className="switch" type="checkbox" />
                <div>
                    <div></div>
                </div>
            </label>
        </div>
    )
}

export default Switch
