import cookie from 'react-cookies';
import BaseApi from './../lib/BaseApi';
import {SERVICE_API} from './../constants';
import { httpClient } from 'utils/HttpClient'

class BackendUserApi extends BaseApi
{
    static url() {
        return '/backend/backusers';
    }

    static create(data) {
        return httpClient.post(this.url(), data).then(res => res.data)
    }

    static update(data, id) {
        return httpClient.put(this.url() + '/' + id, data).then(res => res.data)
    }

    static setpassword(data, id) {
        return httpClient.put(this.url() + '/setpassword/' + id, data).then(res => res.data)
    }
}

export default BackendUserApi;