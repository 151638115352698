import React from 'react'
import cookie from 'react-cookies'
import AuthenticateApi from 'api/AuthenticateApi'

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    login(data) {
        AuthenticateApi
            .login(data)
            .then((res) => {
                if(res.token) {
                    cookie.save('BNTOKEN', res.token);
                    setTimeout(() => {
                        window.location = '/dashboard'
                    }, 1000);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        let {email, password} = this.state
        this.login({email: email, password: password})
    }

    render() {
        let {email, password} = this.state
        return (
            <div>
                <div className="row" style={{backgroundColor: '#fff'}}>
                    <div className="col-sm-12 text-center">
                        <img src={`https://app.just-id.com/images/Logo.png`} style={{width: '250px'}} alt="Logo"/>
                    </div>
                </div>
                <section className="material-half-bg">
                    <div className="cover"></div>
                </section>
                <section className="login-content">
                    <div className="login-box">
                        <form className="login-form" method="POST" onSubmit={this.handleSubmit}>
                            <h3 className="login-head">
                                <i className="fa fa-lg fa-fw fa-user"></i> SIGN IN
                            </h3>
                            <div className="form-group">
                                <label className="control-label">E-Mail</label>
                                <input className="form-control" type="text" placeholder="Email" autoFocus name="email" value={email} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <label className="control-label">PASSWORD</label>
                                <input className="form-control" type="password" placeholder="Password" name="password" value={password} onChange={this.handleChange}/>
                            </div>
                            
                            <div className="form-group btn-container">
                                <button className="btn btn-primary btn-block"><i className="fa fa-sign-in fa-lg fa-fw"></i>SIGN IN</button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )
    }
}

export default Login