// export const SERVICE_API = 'https://api.just-id.com';
// export const BASE_API = 'https://api.just-id.com';

let _SERVICE_API;
let _BASE_API;
let _DOMAIN;
let _SOCKET_URL;
if(process.env.NODE_ENV === 'production') {

    _SERVICE_API = 'https://api.just-id.com';
    _BASE_API = 'https://api.just-id.com';
    _DOMAIN = 'backend.just-id.com';
    _SOCKET_URL = 'https://api.just-id.com';

} else {

    // _SERVICE_API = 'http://localhost:8080';
    // _BASE_API = 'http://localhost:8080';
    _SERVICE_API = 'https://api.just-id.com';
    _BASE_API = 'https://api.just-id.com';
    _DOMAIN = 'localhost';
    _SOCKET_URL = 'http://localhost:3500';

}

export const SERVICE_API = _SERVICE_API;
export const BASE_API = _BASE_API;
export const DOMAIN = _DOMAIN;
export const SOCKET_URL = _SOCKET_URL;