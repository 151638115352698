import {combineReducers} from 'redux';
import socket from './socketReducer';
import user from './userReducer';
import location from './locationReducer';
import tracking from './trackingReducer';
import device from './deviceReducer';

const rootReducer = combineReducers({
  // short hand property names
    socket,
    user,
    locationStore: location,
    trackingStore: tracking,
    deviceStore: device
})

export default rootReducer;