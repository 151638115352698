import React, { useEffect, useState } from "react"
import cookie from 'react-cookies'

function Overview() {
    const [state, setState] = useState({})
    useEffect(() => {
        const getSummary = async () => {
            const jwtToken = cookie.load('BNTOKEN')
            if (jwtToken != null) {
                const res = await fetch("https://kkp-api.just-id.com/backend/fee/summary", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${jwtToken}`
                    }
                })
                const { data } = await res.json()
                setState(data)
            }
        }
        getSummary()
    }, [])
    return (
        <div>
            <h1>Payment Overview</h1>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Total Fee</td>
                                        <td>{state?.total_fee || 0}</td>
                                    </tr>
                                    <tr>
                                        <td>QR Payment Fee</td>
                                        <td>{state?.payment_fee || 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Withdraw Fee</td>
                                        <td>{state?.transfer_fee || 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Fee</td>
                                        <td>{state?.bank_fee || 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview