import BaseApi from 'lib/BaseApi'
import { httpClient } from 'utils/HttpClient'

class CouponApi extends BaseApi
{
    static url() {
        return '/backend/coupons';
    }

    static type() {
        return 'coupons';
    }

    static create(data) {
        return httpClient.post(this.url(), data).then(res => res.data)
    }

    static update(data, id) {
        return httpClient.put(this.url() + '/' + id, data).then(res => res.data)
    }

    static sendInvite(id) {
        return httpClient.get(`/backend/users/sendinvite/${id}`).then(res => res.data)
    }

    static setpassword(data, id) {
        return httpClient.put(this.url() + '/setpassword/' + id, data).then(res => res.data)
    }
}

export default CouponApi