import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import UserApi from './../../../api/UserApi'
import Pagination from './../../../components/Paginate'

function UserPage() {
    const [search, setSearch] = useState('')
    const [state, setState] = useState({
        params: {
            search: '',
            page: localStorage.getItem('page') ? localStorage.getItem('page') : 1,
        },
        items: [],
        metadata: localStorage.getItem('metadata') ? JSON.parse(localStorage.getItem('metadata')) : {}
    })

    useEffect(() => {
        (async() => {
            let { docs, ...rest } = await UserApi.query(state.params)
            localStorage.setItem('metadata', JSON.stringify(rest))
            setState(state => ({
                ...state,
                items: docs,
                metadata: rest
            }))
        })()
    }, [state.params])

    const onSearch = (e) => {
        e.preventDefault()
        setState(state => ({
            ...state,
            params: {
                ...state.params,
                search,
            }
        }))
    }

    const pageChange = (p) => {
        localStorage.setItem('page', p)
        setState(state => ({
            ...state,
            params: {
                ...state.params,
                page: p,
            }
        }))
    }

    console.log(state.metadata.currentPage)

    return (
        <div className="card">
            <div className="card-body">
                <div className="row form-group">
                    <div className="col-sm-8">
                        <Link className="btn btn-primary" to={{pathname:`/users/create`, state: {title: 'Create User'}}}>
                            <i className="fa fa-user-plus"></i> Create User
                        </Link>
                    </div>
                    <div className="col-sm-4">
                        <form onSubmit={onSearch}>
                            <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </form>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>UID</th>
                            <th>Org ID</th>
                            <th>Ref</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.items && state.items.length > 0 && state.items.map(user => {
                                return (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.app_organization_id}</td>
                                        <td>{user.ref_code}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.seller ? user.seller.user.name : ''}</td>
                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Action <span className="caret"></span>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link to={{pathname:`/users/${user.id}`, state: {title: 'Edit User'}}}><i className="fa fa-pencil"></i> Edit</Link>
                                                    </li>
                                                    <li>
                                                        <a href="" onClick={(e) => {this.sendInvite(user.id);e.preventDefault()}}>Send User login</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination 
                    currentPage={state.metadata.currentPage}
                    pageCount={state.metadata.totalPages}
                    rowCount={10}
                    itemTotal={state.metadata.totalItems}
                    onPageChange={(n) => pageChange(n)}
                />
            </div>
        </div>
    )
}


export default UserPage
