import * as socket from './socketAction';
import * as user from './userAction';
import * as location from './locationAction';
import * as tracking from './trackingAction';
import * as device from './deviceAction';

export default {
    // ...teampermission,
    // ...projectsetting,
    ...user,
    ...socket,
    ...location,
    ...tracking,
    ...device
}