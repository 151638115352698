import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AppOrgResource } from 'api/AppOrgApi'
import Pagination from 'components/Paginate'

function AppOrganization() {

    const [search, setSearch] = useState('')
    const [state, setState] = useState({
        params: {
            search: '',
            page: localStorage.getItem('page') ? localStorage.getItem('page') : 1,
        },
        items: [],
        metadata: localStorage.getItem('metadata') ? JSON.parse(localStorage.getItem('metadata')) : {}
    })

    useEffect(() => {
        (async() => {
            let { docs, ...rest } = await AppOrgResource.fetch(state.params)
            localStorage.setItem('metadata', JSON.stringify(rest))
            setState(state => ({
                ...state,
                items: docs,
                metadata: rest
            }))
        })()
    }, [state.params])

    const onSearch = () => {
        setState(state => ({
            ...state,
            params: {
                ...state.params,
                search,
            }
        }))
    }

    const pageChange = (p) => {
        localStorage.setItem('page', p)
        setState(state => ({
            ...state,
            params: {
                ...state.params,
                page: p,
            }
        }))
    }

    const resetSearch = () => {
        setSearch('')
        setState(state => ({
            ...state,
            is_loading: true,
            params: {
                page: 1,
                search: '',
            }
        }))
    }

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="line-head">Organization</h4>
                <div className="row form-group">
                    <div className="col-sm-5">
                        <form onSubmit={onSearch}>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                                <span className="input-group-btn">
                                    <button className="btn btn-default" type="button" onClick={onSearch}><i className="fa fa-search"></i></button>
                                    <button className="btn btn-link" type="button" onClick={resetSearch}><i className="fa fa-remove"></i></button>
                                </span>
                            </div>
                        </form>
                    </div>
                    <div className="col-sm-7 text-right">
                        <Link className="btn btn-default" to="/organizations/create">Setup new org</Link>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Org ID</th>
                            <th>Customer ID</th>
                            <th>Org Name</th>
                            <th>Company Name</th>
                            <th>Service Permission</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.items && state.items.length > 0 ?
                                state.items.map(org => {
                                    return (
                                        <tr key={org.id + org.org_name}>
                                            <td>ORG{(1e13 + "" + org.id).slice(-6)}</td>
                                            <td>{org.customer_eid}</td>
                                            <td>{org.org_name}</td>
                                            <td>{org.company_name}</td>
                                            <td>
                                                {
                                                    org.service_permissions && org.service_permissions.length > 0 ?
                                                        org.service_permissions.filter(f => f.permission === 'active').map(permission => {
                                                            return (
                                                                <div key={permission.id + permission.service_name}>
                                                                    <span className={'badge'}>{permission.service_name}</span>
                                                                </div>
                                                            )
                                                        })
                                                    : null
                                                }
                                            </td>
                                            <td>
                                                <Link className="btn btn-warning btn-sm" to={{pathname:`/organizations/${org.id}`, state: {title: 'Edit Org'}}}>
                                                    <i className="fa fa-edit"></i> Edit
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            : null
                        }
                    </tbody>
                </table>
                <Pagination 
                    currentPage={state.metadata.currentPage || 1}
                    pageCount={state.metadata.totalPages || 1}
                    rowCount={state.metadata.limit || 20}
                    itemTotal={state.metadata.totalItems || 1}
                    onPageChange={(n) => pageChange(n)}
                />
            </div>
        </div>
    )
}


export default AppOrganization
